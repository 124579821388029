import { AppProps } from 'next/app';
import { FC } from 'react';

const NextApp: FC<AppProps> = ({ Component, pageProps, children }) => (
  <>
    {children}
    <Component {...pageProps} />
  </>
);

export default NextApp;
