import { useAuthInfoCollectByLocation } from '@anm/auth/hooks/useAuthInfoCollect';
import ModalNotificationRoot from '@anm/components/modals/NotificationModal/ModalNotificationRoot';
import * as sentry from '@anm/helpers/sentry/client';
import NextApp from '@anm/pages/App';
import { lightTheme } from '@anm/styles/theme';
import { AppProps } from 'next/app';
import { FC } from 'react';
import { ThemeProvider } from 'styled-components';

import lfConfig from '../config';
import GlobalStyle from '../styles/GlobalStyle';

sentry.init(lfConfig.sentry);

const App: FC<AppProps> = props => {
  useAuthInfoCollectByLocation();

  return (
    <ThemeProvider theme={lightTheme}>
      <NextApp {...props}>
        <ModalNotificationRoot />
        <GlobalStyle />
      </NextApp>
    </ThemeProvider>
  );
};

export default App;
