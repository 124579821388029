import getFromCdn from '@anm/helpers/getFromCdn';

import { Themes } from '.';

const darkTheme: Themes = {
  textColor: 'var(--gray_20)',
  disabledTextColor: 'var(--gray_100)',
  headerBg: 'var(--gray_800)',
  bodyBg: 'var(--gray_950)',
  broadcastAndFeedsBg: 'var(--gray_950)',
  controlsBg: '#292A2B',
  tabContentBg: 'var(--gray_900)',
  tabPanelBg: 'var(--gray_800)',
  tabActiveItemBg: '#3F4244',
  tabItemBgMobile: 'var(--gray_700)',
  tabActiveItemBgMobile: 'var(--gray_900)',
  tabIconColorFilter: 'var(--gray_to_white)',
  tabItemHoverBg: 'var(--gray_500)',
  scenesWrapperBg: 'var(--gray_800)',
  inputBorder: 'var(--gray_600)',
  inputBg: 'transparent',
  inputShadow: 'none',
  dropdownAndModalBg: 'var(--gray_700)',
  modalBordersColor: 'var(--gray_600)',
  whiteToLightBlueButtonBg: 'var(--gray_700)',
  whiteToLightBlueButtonHoverBg: 'var(--gray_600)',
  disabledSwitchBg: 'var(--gray_500)',
  selectOptionActiveBg: 'var(--gray_500)',
  selectOptionHoverBg: '#575C5E',
  broadcastControlsTextColor: '#8B9499',
  broadcastControlsHoverBg: '#3F4244',
  broadcastControlsCaretHoverBg: 'var(--gray_600)',
  chatGuestMessageBg: 'var(--gray_700)',
  logoImage: `${getFromCdn('images/streaming/logo-dark-theme.svg')}`,
  blackDropdownArrowColorFilter: 'var(--any_to_gray_400)',
  layoutControlsBg: 'var(--gray_400)',
  layoutControlsImageFilter: 'var(--any_to_gray_600)',
  layoutControlsDropdownBg: 'var(--gray_600)',
  tabItemSeparatorBg: '#71777A',
  permissionItemActiveBg: 'var(--gray_500)',
  permissionItemHoverBg: 'var(--gray_600)',
  radioButtonCircleBorderColor: 'var(--gray_500)',
  radioButtonCircleBg: 'var(--gray_700)',
  webkitScrollbarBg: 'var(--gray_900)',
  webkitScrollbarTrackBg: 'var(--gray_900)',
  webkitScrollbarThumbBg: 'var(--gray_950)',
  liveChatTabBgActiveItem: 'var(--gray_600)',
  liveChatTabBgHoverItem: '#575C5E',
  dropdownArrowSideBg: 'var(--gray_700)',
  destinationHeaderBlockBg: 'var(--gray_600)',
  brandSelectorHoverBg: 'var(--gray_600)',
  openScenesButtonTextColorOpenState: '#F3F6F7',
  openScenesButtonCaretFilterOpenState: 'none',
  openScenesButtonTextColorCloseState: '#F3F6F7',
  openScenesButtonBgOpenState: 'var(--gray_800)',
  brandMenuItemBoxShadow: '0px 2px 10px var(--gray_600);',
  brandMenuHoverBg: 'var(--gray_700)',
  errorPageGradient: 'linear-gradient(to top, var(--gray_950), var(--gray_700))',
  addBrandBrandPlaceholder: 'var(--gray_20)',
  addMediaTabTextColor: 'var(--gray_500)',
  stockSearchHeaderBg: 'var(--gray_400)',
  activeDestinationTabBg: '#71777A',
  destinationItemHover: '#575c5e',
  destinationsEmptyTextColor: '#fff',
  textStylePreviewBackground: '#71777a',
  commentActiveDestinationBg: 'var(--gray_600)',
  feedBorderColor: 'var(--gray_500)',
  qrCodeBg: '#333536',
  qrCodeColor: '#f3f6f7',
  darkBorderColor: 'var(--gray_500)',
  qrCodeItemBg: '#3F4244',
  mediaHeaderButtonsColor: '#F3F6F7',
  mediaHeaderButtonsBg: '#575C5E',
  mediaHeaderButtonsHover: '#71777A',
  audioItemBG: '#333536',
  selectedAudioOutline: '#F3F6F7',
  audioTitle: '#F3F6F7',
  audioTime: '#8B9499',
  audioItemHover: '#3F4244',
  audioPlayPause: '#8B9499',
  audioPlayPauseHover: '#F3F6F7',
  volumeRangeColor: `#F3F6F5`,
  volumeRangeInactiveColor: `#8B9499`,
  rangeThumbColor: '#F3F6F5',
  selectedMediaOverlay: '#f3f6f7',
  dropdownText: '#F3F6F7',
  dropdownSecondaryText: '#8B9499',
  captionsHeaderBorderBottom: '#71777A',
  captionsTitle: '#F3F6F7',
  dropdownBG: '#333536',
  threeDotsHover: '#575C5E',
  bannerBG: '#333536',
  switchWrapperBG: '#3187B6',
  editBannerBorderColor: '#71777A',
  editBannerActiveColor: '#3187B6',
  editBannerInactiveElement: 'transparent',
  bannerControlHover: '#71777A',
  bannerActiveControlHover: 'var(--blue_250)',
  bannerControlText: '#F3F6F7',
  bannerControlSecondaryText: '#8B9499',
  editBannerActiveInputColor: '#F3F6F7',
  chatTabTitle: '#F3F6F7',
  chatTabColor: '#F3F6F7',
  textAreaInactiveBorder: '#71777A',
  textAreaActiveBorder: '#F3F6F7',
  disabledShowPrompt: '#2A5165',
  promptControlLabel: '#8B9499',
  qrCodesTitleColor: '#F3F6F7',
  selectedQROutline: '#F3F6F7',
  sideBarTextColor: '#8B9499',
  sideBarActiveColor: '#E0E7EB',
  sideBarItemActiveBg: '#3F4244',
  checkedSwitchBackgroundColor: 'var(--blue_250)',
  uncheckedSwitchBackgroundColor: 'var(--gray_500)',
  raffleCardBg: 'var(--gray_700)',
  raffleHashtagInputBg: 'var(--gray_700)',
  radioButtonNotActiveColor: 'var(--gray_500)',
  radioButtonActiveColor: 'var(--blue_250)',
  raffleHintTextColor: 'var(--gray_20)',
  checkboxButtonActiveColor: 'var(--blue_250)',
  smallTabActiveBg: 'var(--gray_700)',
  smallTabHoverBg: 'var(--gray_600)',
  textToolAlignmentActiveColor: 'var(--gray_600)',
  textToolAlignmentActiveFilter: 'brightness(1.9)',
  textToolPlacementBGColor: 'var(--gray_700)',
  textToolPlacementActiveBGColor: 'var(--gray_600)',
  textToolPlacementDotBGColor: 'var(--gray_500)',
  textToolPlacementDotActiveBGColor: 'var(--gray_50)',
  textToolMessageBG: 'rgba(31, 31, 32, 0.55)'
};

export default darkTheme;
