import getFromCdn from '@anm/helpers/getFromCdn';

const lightTheme = {
  textColor: 'var(--gray_900)',
  disabledTextColor: 'var(--gray_300)',
  headerBg: 'var(--white)',
  bodyBg: 'var(--white)',
  broadcastAndFeedsBg: 'var(--gray_20)',
  controlsBg: 'var(--white)',
  tabContentBg: 'var(--white)',
  tabPanelBg: 'var(--gray_10)',
  tabActiveItemBg: 'var(--white)',
  tabItemBgMobile: 'var(--gray_10)',
  tabActiveItemBgMobile: '#fff',
  tabIconColorFilter: 'var(--gray_to_black)',
  tabItemHoverBg: 'var(--gray_50)',
  scenesWrapperBg: 'var(--white)',
  inputBorder: '#cbdce5',
  inputBg: 'var(--white)',
  inputShadow: '0 0 10px 0 rgba(255, 255, 255, 0.5)',
  dropdownAndModalBg: 'var(--white)',
  modalBordersColor: 'var(--gray_50)',
  whiteToLightBlueButtonBg: 'var(--white)',
  whiteToLightBlueButtonHoverBg: '#e1f9ff',
  disabledSwitchBg: 'var(--gray_50)',
  selectOptionActiveBg: '#E9EEF1',
  selectOptionHoverBg: 'rgba(9, 188, 228, 0.1)',
  broadcastControlsTextColor: 'var(--gray_500)',
  broadcastControlsHoverBg: 'var(--gray_10)',
  broadcastControlsCaretHoverBg: '#e0e4e6',
  chatGuestMessageBg: 'var(--gray_10)',
  logoImage: `${getFromCdn('images/streaming/logo.svg')}`,
  blackDropdownArrowColorFilter: 'none',
  layoutControlsBg: 'var(--white)',
  layoutControlsImageFilter: 'none',
  layoutControlsDropdownBg: 'var(--gray_20)',
  tabItemSeparatorBg: 'rgba(0, 56, 87, 0.2)',
  permissionItemActiveBg: 'var(--blue_20)',
  permissionItemHoverBg: 'var(--gray_10)',
  radioButtonCircleBorderColor: 'var(--gray_50)',
  radioButtonCircleBg: 'var(--white)',
  webkitScrollbarBg: 'var(--white)',
  webkitScrollbarTrackBg: 'var(--white)',
  webkitScrollbarThumbBg: '#babac0',
  liveChatTabBgActiveItem: 'var(--blue_20)',
  liveChatTabBgHoverItem: 'var(--blue_100_10)',
  dropdownArrowSideBg: 'var(--gray_50)',
  destinationHeaderBlockBg: 'var(--gray_40)',
  brandSelectorHoverBg: '#F4F6F7',
  openScenesButtonTextColorOpenState: 'var(--gray_900)',
  openScenesButtonTextColorCloseState: 'var(--white)',
  openScenesButtonCaretFilterOpenState: 'none',
  openScenesButtonBgOpenState: 'var(--white)',
  brandMenuItemBoxShadow: '0px 5px 20px rgba(1, 83, 130, 0.2);',
  brandMenuHoverBg: 'var(--gray_50)',
  errorPageGradient: 'linear-gradient(to top, #def5ff, #ffffff)',
  addBrandBrandPlaceholder: '#8B9499',
  addMediaTabTextColor: 'rgb(145, 158, 170)',
  stockSearchHeaderBg: '#e1f9ff',
  activeDestinationTabBg: '#D9F2FF',
  destinationItemHover: '#f4f6f7',
  destinationsEmptyTextColor: '#71777A',
  textStylePreviewBackground: '#f4f6f7',
  commentActiveDestinationBg: 'var(--gray_20)',
  feedBorderColor: 'rgba(0, 56, 87, 0.05)',
  qrCodeBg: '#fff',
  qrCodeColor: '#000',
  darkBorderColor: 'var(--gray_100_20)',
  qrCodeItemBg: 'var(--white)',
  mediaHeaderButtonsColor: 'var(--gray_900)',
  mediaHeaderButtonsBg: 'var(--gray_50)',
  mediaHeaderButtonsHover: 'var(--gray_40)',
  audioItemBG: '#f4f6f7',
  selectedAudioOutline: '#30AEF2',
  audioTitle: '#292a2b',
  audioTime: '#8b9499',
  audioItemHover: '#d9f2ff',
  audioPlayPause: '#CCD7DD',
  audioPlayPauseHover: '#30AEF2',
  volumeRangeColor: `#30AEF2`,
  volumeRangeInactiveColor: `#ccd7dd`,
  rangeThumbColor: '#30AEF2',
  selectedMediaOverlay: '#2D98D2',
  dropdownText: 'var(--gray_900)',
  dropdownSecondaryText: 'var(--gray_500)',
  captionsHeaderBorderBottom: '#e0e7eb',
  captionsTitle: 'var(--gray_900)',
  dropdownBG: 'var(--white)',
  threeDotsHover: 'rgba(32,171,210,0.2)',
  bannerBG: 'var(--gray_10)',
  switchWrapperBG: '#2d98d2',
  editBannerBorderColor: '#ccdde6',
  editBannerActiveColor: '#30AEF2',
  editBannerInactiveElement: '#F4F6F7',
  bannerControlHover: '#E0E7EB',
  bannerActiveControlHover: '#D9F2FF',
  bannerControlText: 'var(--gray_900)',
  bannerControlSecondaryText: '#8b9499',
  editBannerActiveInputColor: '#30AEF2',
  chatTabTitle: 'var(--gray_900)',
  chatTabColor: 'var(--gray_900)',
  textAreaInactiveBorder: 'var(--gray_400)',
  textAreaActiveBorder: 'var(--blue_100)',
  disabledShowPrompt: 'var(--white)',
  promptControlLabel: 'var(--gray_400)',
  qrCodesTitleColor: 'var(--gray_900)',
  selectedQROutline: 'var(--blue_100)',
  sideBarTextColor: '#71777A',
  sideBarActiveColor: '#1F2020',
  sideBarItemActiveBg: '#E0E7EB',
  checkedSwitchBackgroundColor: 'var(--blue_100)',
  uncheckedSwitchBackgroundColor: 'var(--gray_50)',
  raffleCardBg: 'var(--gray_10)',
  raffleHashtagInputBg: 'var(--white)',
  radioButtonNotActiveColor: 'var(--gray_100)',
  radioButtonActiveColor: 'var(--blue_100)',
  raffleHintTextColor: 'var(--gray_400)',
  checkboxButtonActiveColor: 'var(--blue_100)',
  smallTabActiveBg: 'var(--gray_50)',
  smallTabHoverBg: 'var(--gray_20)',
  textToolAlignmentActiveColor: 'var(--gray_50)',
  textToolAlignmentActiveFilter: 'brightness(0.3)',
  textToolPlacementBGColor: 'var(--gray_10)',
  textToolPlacementActiveBGColor: 'var(--gray_50)',
  textToolPlacementDotBGColor: 'var(--gray_500)',
  textToolPlacementDotActiveBGColor: 'var(--gray_970)',
  textToolMessageBG: '#f1f4f5'
};

export default lightTheme;
