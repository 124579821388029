import { createGlobalStyle } from 'styled-components';
import { palette } from '@anm/styles/helpers';

export default createGlobalStyle`
  ${palette};
  #modal-root {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    position: fixed;
    pointer-events: none;
  }
  html {
    height: auto;
  }

  body {
    display: block !important;
    margin: 0;
    padding: 0;
    min-width: 320px;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    
  }

  * {
      box-sizing: border-box;
    }

  ul,
  li {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  a {
    text-decoration: none;
    transition: 0.3s;
    color: #2294df;
  }

  button,
  input {
    padding: 0;
    outline: none;
    background: none;
    border: none;
    font-family: inherit;
  }

  input:focus {
    outline: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    font-weight: normal;
  }

  img, object {
    max-width: 100%;
  }
  
  main > section:nth-of-type(2) {
    h2 {
      font-size: 40px;
      line-height: 54px;
    }
  }
`;
