import * as analytic from '@anm/analytic';
import * as affiliate from '@anm/helpers/affiliate';
import isId from '@anm/helpers/is/isId';
import { toObject } from '@anm/helpers/parseParams';
import { collectAuthInfo } from '@anm/helpers/stateful/collectAuthInfo';
import getUTMHeaders from '@anm/helpers/utm/getUTMHeaders';
import { Router as NextRouter } from 'next-routes';
import { useEffect } from 'react';

type RouterMarketingQuery = { app_id?: string; embed?: string };
const getMarketingHeaders = ({ app_id, embed }: RouterMarketingQuery) => {
  const appId = app_id || (isId(embed as string) && embed);

  return {
    'anm-stat-current-url': location.href,
    ...(appId && { 'anm-ref-app': appId })
  };
};

type GetAuthHeadersProps = { path?: string; query: RouterMarketingQuery };
const getAuthHeaders = async ({ path, query = {} }: GetAuthHeadersProps) => ({
  ...(await analytic.getHeaders()),
  ...affiliate.getHeaders(),
  ...getMarketingHeaders(query),
  ...getUTMHeaders(path)
});

const collectAuthHeaders = async (props: GetAuthHeadersProps) =>
  collectAuthInfo(await getAuthHeaders(props));

export const useAuthInfoCollectByRouter = (Router: NextRouter) => {
  const handleRouteChangeComplete = () =>
    collectAuthHeaders({ query: Router.query, path: Router.router?.asPath });

  useEffect(() => {
    handleRouteChangeComplete();
    Router.router?.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () =>
      Router.router?.events.off(
        'routeChangeComplete',
        handleRouteChangeComplete
      );
  }, []);
};

export const useAuthInfoCollectByLocation = () => {
  useEffect(() => {
    const query = toObject<RouterMarketingQuery>(window.location.search);

    collectAuthHeaders({ query, path: location.href });
  }, []);
};
