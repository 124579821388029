const createScriptEl = (
  src: string,
  onload: (value?: {} | PromiseLike<{}> | undefined) => void,
  onerror: (reason?: any) => void
) => {
  const node = document.getElementsByTagName('head')[0] || document.body;
  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.async = true;
  script.src = src;

  script.onload = (script as any).onreadystatechange = onload;
  script.onerror = onerror;

  node.appendChild(script);

  return script;
};

const cachePromise: { [key: string]: Promise<any> } = {};

const loadScripts = (modules: string[]) => {
  const modulesPromised = modules
    .filter(m => m)
    .map(
      m =>
        cachePromise[m] ||
        (cachePromise[m] = new Promise(createScriptEl.bind(null, m)))
    );

  return Promise.all(modulesPromised);
};

export default loadScripts;
