import splitWith from '@anm/helpers/string/splitWith';
import compose from 'lodash/fp/compose';

import getUTMQuery from '../getUTMQuery';

const getUTMValue = (query: string) =>
  compose(splitWith('&', ';'), getUTMQuery)(query);

const getUTMHeaders = (query = location.search) => {
  const headers = getUTMValue(query);
  return {
    ...(headers && {
      'anm-mkt-utm': headers
    })
  };
};
export default getUTMHeaders;
